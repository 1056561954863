import { Container } from 'react-bootstrap';
import { ERROR } from '../../utils/constants';
import { Content, Navbar, NavigationButton, Wrapper } from './styles';
import logoInside from '../../assets/images/logo-inside-brasil.svg';

function Error(): JSX.Element {
  return (
    <Wrapper>
      <Navbar>
        <Container>
          <Navbar.Brand>
            <img
              alt="Logo da Inside"
              src={logoInside}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{' '}
            <span className="brand">
              Inside <b>Brasil</b>
            </span>
          </Navbar.Brand>
        </Container>
      </Navbar>

      <Content>
        <h2>{ERROR}</h2>
        <NavigationButton to={`/${localStorage.getItem('@inside/module') ?? 'none'}`}>
          Voltar para o início
        </NavigationButton>
      </Content>
    </Wrapper>
  );
}

export default Error;
