type Variables = {
  answer: {
    activator_id: string | undefined;
    question_id: string | undefined;
  };
  media?: File;
  sourceId: string;
  qtyReceived: number;
  qtySent: number;
  text?: string;
};

type Return = {
  addAnswerMedia: ({ variables }: { variables: Variables }) => void;
};

export const nop = (): number => {
  return 0;
};

export const useAddAnswer = (): Return => {
  const addAnswerMedia = async ({ variables }: { variables: Variables }): Promise<Response> => {
    const data = new FormData();

    let query: string;

    if (variables.media) {
      query = `{ "query":"mutation Mutation($media: Upload) {addAnswer(answer: {activator_id: \\"${variables.answer.activator_id}\\",question_id: \\"${variables.answer.question_id}\\"} media: $media, source_id: \\"${variables.sourceId}\\", qty_received: ${variables.qtyReceived}, qty_sent: ${variables.qtySent}){id}}"}`;
      data.append('operations', query);
      data.append('map', '{"media": ["variables.media"]}');
      data.append('media', variables.media);
    }

    const result = await fetch(process.env.REACT_APP_API_URL ?? '', {
      method: 'POST',
      credentials: 'include',
      mode: 'no-cors',
      body: data,
    });

    return result;
  };

  return { addAnswerMedia };
};
