import commonStyles from './common';

export default {
  ...commonStyles,
  colors: {
    background: {
      light: '#36264d',
      main: '#281C39',
    },
    primary: {
      light: '#00F5D8',
      main: '#0BC8B4',
      dark: '#099485',
    },
    text: {
      light: '#00F5D8',
      main: '#0BC8B4',
      dark: '#099485',
    },
    error: {
      main: '#F05359',
    },
  },
  colorsRGB: {
    background: {
      light: (opacity = 1): string => `rgba(54, 38, 77, ${opacity})`,
      main: (opacity = 1): string => `rgba(48, 28, 57, ${opacity})`,
    },
    primary: {
      light: (opacity = 1): string => `rgba(128, 255, 240, ${opacity})`,
      main: (opacity = 1): string => `rgba(0, 245, 216, ${opacity})`,
    },
  },
};
