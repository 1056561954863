import { ApolloError, gql, useQuery } from '@apollo/client';
import Source from '../../../@types/Source';

const GET_SOURCES = gql`
  query Sources {
    sources {
      id
      name
    }
  }
`;

export function useGetSources(): {
  data: Source[];
  loading: boolean;
  error: ApolloError | undefined;
} {
  const { data, loading, error } = useQuery(GET_SOURCES);

  if (error) {
    return { data: [] as Source[], loading: false, error };
  }
  return { data: data ? data.sources : ([{}] as Source[]), loading, error };
}
