import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  gql,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

type returnType = {
  addAnswerText: (
    options?:
      | MutationFunctionOptions<unknown, OperationVariables, DefaultContext, ApolloCache<unknown>>
      | undefined
  ) => Promise<FetchResult<unknown, Record<string, unknown>, Record<string, unknown>>>;
};

const ADD_ANSWER_TEXT = gql`
  mutation Mutation($answer: AnswerInput, $sourceId: UUID, $qtyReceived: Int, $qtySent: Int, $text: String) {
    addAnswer(
      answer: $answer
      source_id: $sourceId
      qty_received: $qtyReceived
      qty_sent: $qtySent
      text: $text
    ) {
      id
    }
  }
`;

export function useAddAnswerText(): returnType {
  const [addAnswerText] = useMutation(ADD_ANSWER_TEXT);

  return { addAnswerText };
}
