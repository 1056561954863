import { ApolloError, gql, useQuery } from '@apollo/client';
import CaptureModule from '../../../@types/CaptureModule';

const GET_CAPTURE_MODULES_BY_URL = gql`
  query CaptureModuleByUrl($url: String!) {
    captureModuleByUrl(url: $url) {
      id
      name
      start_date
      end_date
      question {
        id
        text
        sequence
        theme
      }
    }
  }
`;

export function useGetCaptureModuleByURL(url: string | undefined): {
  data: CaptureModule | null;
  loading: boolean;
  error: ApolloError | undefined;
} {
  const { data, loading, error } = useQuery(GET_CAPTURE_MODULES_BY_URL, {
    variables: { url },
  });

  if (error) {
    return { data: null, loading: false, error };
  }

  return { data: data ? data.captureModuleByUrl : null, loading, error };
}
