import Container from 'react-bootstrap/Container';
import NavbarBs from 'react-bootstrap/Navbar';
import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background.light};
  min-height: 100vh;
`;

export const Navbar = styled(NavbarBs)`
  background-color: ${({ theme }) => theme.colors.background.main};

  .brand {
    margin-left: 8px;
    color: #fff;
  }

  .activator-data {
    .activator-name {
      color: #fff;
      padding-right: 8px;
      margin-right: 8px;
      border-right: 1px solid #fff;
      font-weight: 600;
    }

    button {
      background-color: ${({ theme }) => theme.colors.primary.main};
      color: #fff;
      border: none;
      padding: 4px 8px;
      border-radius: 4px;
      transition: background-color 0.2s ease-in-out;
      font-weight: 600;

      &:hover {
        background-color: ${({ theme }) => theme.colors.primary.dark};
      }
    }
  }

  @media (min-width: 769px) {
    .navbar-collapse {
      display: flex;
      justify-content: flex-end;
    }
  }

  @media (max-width: 768px) {
    .activator-data {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 32px 16px;

      .activator-name {
        border-right: 0;
      }
    }
  }
`;

export const Content = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  color: #fff;
`;
