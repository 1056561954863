import Container from 'react-bootstrap/Container';
import FormBs from 'react-bootstrap/Form';
import ButtonBs from 'react-bootstrap/Button';
import styled from 'styled-components';

export const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #fff;

  input {
    height: 40px;
    font-size: 18px;
    width: 100%;
    font-family: 'Raleway', sans-serif;

    &:focus {
      box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colorsRGB.primary.main(0.25)};
    }
  }

  img {
    width: 100%;
  }
`;

export const Form = styled(FormBs)`
  min-height: 30vh;
  background-color: ${({ theme }) => theme.colors.background.light};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 66px 66px 66px;
`;

export const Button = styled(ButtonBs)`
  background-color: ${({ theme }) => theme.colors.primary.main};
  font-family: 'Raleway', sans-serif;
  border: 0;
  width: 150px;
  transition: background-color 0.2s ease-in-out;

  &:hover,
  &:focus,
  &:active {
    background-color: ${({ theme }) => theme.colors.primary.dark};
  }

  &:focus,
  &:active,
  &:active:focus {
    box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colorsRGB.primary.main(0.25)};
  }
`;

export const ErrorMessage = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.error.main};
`;

export const ForgotPasswordMessage = styled.a`
  text-align: center;
  display: block;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary.main};
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &:hover,
  &:focus,
  &:active {
    color: ${({ theme }) => theme.colors.primary.dark};
  }
`;
