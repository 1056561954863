import { Navigate, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import SendMedia from './pages/SendMedia';
import SendMediaSequence from './pages/SendMedia/sequence';
import { ACKNOWLEDGMENT, STALLED_CAPTURE_MODULE } from './utils/constants';
import Error from './pages/Error';
import AlreadySent from './pages/AlreadySent';

function Router(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/none" />} />
      <Route path="/:captureModuleURL" element={<Login />} />
      <Route path="/sendmedia" element={<SendMedia />}>
        <Route path=":sequence" element={<SendMediaSequence />} />
        <Route path="stalled" element={<h1 className="text-center">{STALLED_CAPTURE_MODULE}</h1>} />
        <Route path="acknowledgment" element={<h1 className="text-center">{ACKNOWLEDGMENT}</h1>} />
      </Route>
      <Route path="/alreadysent" element={<AlreadySent />} />
      <Route path="/error" element={<Error />} />
      <Route path="*" element={<h1>Not Found</h1>} />
    </Routes>
  );
}

export default Router;
