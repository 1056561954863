/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Content, Navbar, Wrapper } from './styles';

import { AuthenticationContext } from '../../contexts/Authentication';

import logoInside from '../../assets/images/logo-inside-brasil.svg';
import { QuestionsContext } from '../../contexts/Questions';
import { useGetCaptureModuleByURL } from '../../hooks/graphql/captureModule/useGetCaptureModuleByURL';
import { useLogout } from '../../hooks/graphql/Logout/useLogout';
import Question from '../../@types/Question';
import User from '../../@types/user';

function SendMedia(): JSX.Element {
  const authenticationContext = useContext(AuthenticationContext);
  const questionsContext = useContext(QuestionsContext);
  const navigate = useNavigate();
  const { logout } = useLogout();

  const logoutUser = (): void => {
    logout();
    if (questionsContext && questionsContext.setQuestions) {
      questionsContext.setQuestions([] as Question[]);
    }

    if (authenticationContext && authenticationContext.user && authenticationContext.user.setData) {
      authenticationContext.user.setData({} as User);
    }

    localStorage.removeItem('@inside/user');

    if (authenticationContext && authenticationContext.module && authenticationContext.module.setData) {
      authenticationContext.module.setData(undefined);
    }

    localStorage.removeItem('@inside/module');

    navigate('/');
  };

  const {
    data: captureModule,
    loading: loadingCaptureModule,
    error: errorCaptureModule,
  } = useGetCaptureModuleByURL(authenticationContext.module.data);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!loadingCaptureModule) {
      if (captureModule) {
        if (captureModule.end_date && new Date(captureModule.end_date) < new Date()) {
          navigate('/sendMedia/stalled');
        } else if (
          !captureModule.end_date ||
          (captureModule.end_date && new Date(captureModule.end_date) > new Date())
        ) {
          if (
            captureModule.question &&
            captureModule.question.length > 0 &&
            questionsContext &&
            questionsContext.setQuestions &&
            !questionsContext.questions
          ) {
            questionsContext.setQuestions(captureModule.question);
            navigate('/sendMedia/1');
          }
        }
      }

      if (errorCaptureModule) {
        if (
          errorCaptureModule.message === 'not authenticated' ||
          errorCaptureModule.message === 'not authorized'
        ) {
          const userCaptureModule = authenticationContext.module.data;
          return navigate(`/${userCaptureModule ?? 'none'}`);
        }

        if (errorCaptureModule.message === 'Você já enviou uma mídia hoje!') {
          return navigate('/alreadysent');
        }

        return navigate('/error');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingCaptureModule, errorCaptureModule, captureModule]);

  const [expanded, setExpanded] = useState(false);

  const handleToggle = (): void => {
    if (window.innerWidth < 768) {
      setTimeout(() => {
        setExpanded((prevState) => !prevState);
      }, 150);
    }
  };

  return (
    <Wrapper>
      <Navbar expand="md" expanded={expanded}>
        <Container>
          <Navbar.Brand>
            <img
              alt="Logo da Inside"
              src={logoInside}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{' '}
            <span className="brand">
              Inside <b>Brasil</b>
            </span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
          <Navbar.Collapse id="basic-navbar-nav">
            <span className="activator-data">
              <span className="activator-name">{authenticationContext?.user?.data?.name}</span>
              <button type="button" onClick={() => logoutUser()}>
                Sair
              </button>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Content>
        <Outlet />
      </Content>
    </Wrapper>
  );
}

export default SendMedia;
