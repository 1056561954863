import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  gql,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

type returnType = {
  addAnwserNoMedia: (
    options?:
      | MutationFunctionOptions<unknown, OperationVariables, DefaultContext, ApolloCache<unknown>>
      | undefined
  ) => Promise<FetchResult<unknown, Record<string, unknown>, Record<string, unknown>>>;
};

const ADD_ANSWER = gql`
  mutation Mutation($answer: AnswerInput) {
    addAnswer(answer: $answer) {
      id
    }
  }
`;

export function useAddAnswerNoMedia(): returnType {
  const [addAnwserNoMedia] = useMutation(ADD_ANSWER);

  return { addAnwserNoMedia };
}
