import { createContext, useMemo, useState } from 'react';
import Question from '../@types/Question';

type context = {
  questions: Question[] | undefined;
  setQuestions: ((questions: Question[]) => void) | undefined;
};

export const defaultValue: context = {
  questions: undefined,
  setQuestions: undefined,
};

export const QuestionsContext = createContext<context>(defaultValue);

export function QuestionsContextProvider({ children }: { children: JSX.Element }): JSX.Element {
  const [questions, setQuestions] = useState<Question[] | undefined>(undefined);

  const value: context = useMemo(() => {
    return {
      questions,
      setQuestions,
    };
  }, [questions]);

  return <QuestionsContext.Provider value={value}>{children}</QuestionsContext.Provider>;
}
