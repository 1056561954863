import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, ErrorMessage, ForgotPasswordMessage, Form, Wrapper } from './styles';

import logoInside from '../../assets/images/logo-inside.svg';
import { useLogin } from '../../hooks/graphql/Login/useLogin';
import { AuthenticationContext } from '../../contexts/Authentication';

function Login(): JSX.Element {
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { login, loading, data, error } = useLogin();
  const authenticationContext = useContext(AuthenticationContext);
  const params = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    if (params.captureModuleURL === 'none') {
      setErrorMessage('Favor verificar o link recebido e tentar novamente.');
    } else if (email !== '' && password !== '') {
      login({
        variables: {
          email,
          password,
        },
      });
    } else {
      setErrorMessage('Favor preencher e-mail e senha.');
    }
  };

  useEffect(() => {
    if (!loading && data) {
      if (!data.active) {
        setErrorMessage('Sua conta está inativa. Favor entrar em contato com o suporte');
      } else if (
        authenticationContext &&
        authenticationContext.user &&
        authenticationContext.user.setData &&
        authenticationContext.module &&
        authenticationContext.module.setData
      ) {
        authenticationContext.user.setData({
          id: data.id,
          name: data.name,
          code: data.code,
          active: data.active,
        });
        authenticationContext.module.setData(params.captureModuleURL);
        navigate('/sendMedia');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  useEffect(() => {
    if (!loading && error) {
      setErrorMessage(error.message);
      setPassword('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, loading]);

  return (
    <Wrapper>
      <Row>
        <Col>
          <Form>
            <Image src={logoInside} alt="logo da Inside Brasil" />

            <ErrorMessage>{errorMessage}</ErrorMessage>

            <Form.Group className="w-100" controlId="emails">
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                type="email"
                placeholder="Seu email"
                value={email}
                autoComplete="email"
                onChange={(e) => setEmail(e.currentTarget.value)}
                autoFocus
              />
            </Form.Group>

            <Form.Group className="w-100 mt-3" controlId="password">
              <Form.Label>Senha</Form.Label>
              <Form.Control
                type="password"
                placeholder="Sua senha"
                value={password}
                autoComplete="current-password"
                onChange={(e) => setPassword(e.currentTarget.value)}
              />
            </Form.Group>
            <Button className="mt-4" type="submit" onClick={handleSubmit}>
              Entrar
            </Button>
            <ForgotPasswordMessage href={process.env.REACT_APP_INSIDE_URL_FORGOT_PASSWORD} className="mt-4">
              Esqueceu sua senha?
            </ForgotPasswordMessage>
          </Form>
        </Col>
      </Row>
    </Wrapper>
  );
}

export default Login;
