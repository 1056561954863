import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Question from '../../../@types/Question';
import { AuthenticationContext } from '../../../contexts/Authentication';
import { QuestionsContext } from '../../../contexts/Questions';
import { useAddAnswer } from '../../../hooks/graphql/Answer/useAddAnswer';
import { useAddAnswerNoMedia } from '../../../hooks/graphql/Answer/useAddAnswerNoMedia';
import { useAddAnswerText } from '../../../hooks/graphql/Answer/useAddAnswerText';
import { useGetSources } from '../../../hooks/graphql/Source/useGetSources';
import { audioTypes, imageTypes, videoTypes } from '../../../utils/fileTypes';
import {
  ButtonContainer,
  Content,
  CounterButton,
  CounterContainer,
  CounterLabel,
  ErrorMessage,
  Input,
  InputFileLabel,
  InputLabel,
  SelectButton,
  SendButton,
  Text,
  TextArea,
} from './styles';

function SendMediaSequence(): JSX.Element {
  const [receivedAnyContent, setReceivedAnyContent] = useState<'TRUE' | 'FALSE' | 'UNDEFINED'>('UNDEFINED');
  const [receivedCounter, setReceivedCounter] = useState(1);
  const [sentCounter, setSentCounter] = useState(0);
  const [contentType, setContentType] = useState('');
  const [media, setMedia] = useState<File | undefined>();
  const [text, setText] = useState('');
  const [errorMessageMedia, setErrorMessageMedia] = useState('');
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [selectedSource, setSelectedSource] = useState<string>('');
  const [defaultCheck, setDefaultCheck] = useState(false);
  const [especificCheck, setEspecificCheck] = useState(false);
  const { data: sources } = useGetSources();
  const { addAnwserNoMedia } = useAddAnswerNoMedia();
  const { addAnswerText } = useAddAnswerText();
  const { addAnswerMedia } = useAddAnswer();
  const authenticationContext = useContext(AuthenticationContext);

  const questionsContext = useContext(QuestionsContext);
  const navigate = useNavigate();
  const params = useParams();

  const currentPage = Number(params.sequence);

  const currentQuestion = useMemo(() => {
    const question =
      questionsContext && questionsContext.questions
        ? questionsContext.questions.find((q: Question) => q.sequence === currentPage)
        : undefined;
    return question;
  }, [currentPage, questionsContext]);

  const handleContentTypeChange = (type: string): void => {
    setContentType(type);
    setErrorMessageMedia('');
    setMedia(undefined);
  };

  const removeMedia = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setMedia(undefined);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event && event.target && event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      if (contentType === 'IMAGE' || contentType === 'GIF') {
        if (!imageTypes.includes(file.type)) {
          setErrorMessageMedia('O arquivo deve ser uma imagem');
        } else {
          setMedia(file);
          setErrorMessageMedia('');
        }
      } else if (contentType === 'VIDEO') {
        if (!videoTypes.includes(file.type)) {
          setErrorMessageMedia('O arquivo deve ser um vídeo');
        } else {
          setMedia(file);
          setErrorMessageMedia('');
        }
      } else if (contentType === 'AUDIO') {
        if (!audioTypes.includes(file.type)) {
          setErrorMessageMedia('O arquivo deve ser um áudio');
        } else {
          setMedia(file);
          setErrorMessageMedia('');
        }
      }
    } else {
      setMedia(undefined);
      setErrorMessageMedia('Ocorreu um erro ao carregar o arquivo. Tente novamente');
    }
  };

  const handlePageChange = (): void => {
    if (currentPage === questionsContext.questions?.length) {
      navigate('/sendmedia/acknowledgment');
    } else if (
      questionsContext &&
      questionsContext.questions &&
      currentPage < questionsContext.questions?.length &&
      params.sequence
    ) {
      navigate(`/sendmedia/${currentPage + 1}`);
    } else if (!questionsContext || !questionsContext.questions) {
      navigate('/error');
    } else {
      navigate('/alreadysent');
    }
  };

  const cleanStates = (): void => {
    setReceivedAnyContent('UNDEFINED');
    setReceivedCounter(1);
    setSentCounter(0);
    setContentType('');
    setMedia(undefined);
    setText('');
    setErrorMessageMedia('');
    setErrorMessages([]);
    setSelectedSource('');
    setDefaultCheck(false);
    setEspecificCheck(false);
  };

  useEffect(() => {
    if (currentQuestion?.answered) {
      handlePageChange();
    }
  });

  const setAnswered = (): void => {
    if (questionsContext && questionsContext.setQuestions && questionsContext.questions) {
      questionsContext.setQuestions(
        questionsContext.questions.map((q: Question) => {
          if (q.sequence === currentPage) {
            return {
              ...q,
              answered: true,
            };
          }
          return q;
        })
      );
    }
  };

  const handleSubmit = async (): Promise<void> => {
    setErrorMessages([]);

    if (receivedAnyContent === 'UNDEFINED' && currentQuestion?.text) {
      setErrorMessages((prevState) => [...prevState, 'Favor responder à pergunta antes de enviar']);
    } else if (receivedAnyContent === 'FALSE') {
      addAnwserNoMedia({
        variables: {
          answer: {
            activator_id: authenticationContext.user.data?.id,
            question_id: currentQuestion?.id,
          },
        },
      })
        .then(() => {
          setAnswered();
          handlePageChange();
          cleanStates();
        })
        .catch(() => {
          setErrorMessages((prevState) => [...prevState, 'Ocorreu um erro ao enviar a resposta']);
        });
    } else if (
      (receivedAnyContent === 'TRUE' && !media && !text) ||
      (!currentQuestion?.text && !media && !text)
    ) {
      setErrorMessages((prevState) => [...prevState, 'Favor selecionar um arquivo']);
    } else if (
      (receivedAnyContent === 'TRUE' && (media || text)) ||
      (!currentQuestion?.text && (media || text))
    ) {
      if (selectedSource === '') {
        setErrorMessages((prevState) => [...prevState, 'Favor selecionar uma fonte de conteúdo']);
      } else if (!defaultCheck) {
        setErrorMessages((prevState) => [
          ...prevState,
          'Favor confirmar que o conteúdo não traz informações ou imagens pessoais suas ou de pessoas próximas a você',
        ]);
      } else if (!especificCheck && currentQuestion && currentQuestion.theme) {
        setErrorMessages((prevState) => [
          ...prevState,
          `Favor confirmar que o conteúdo é relacionado ${currentQuestion.theme}`,
        ]);
      } else {
        try {
          if (media) {
            addAnswerMedia({
              variables: {
                answer: {
                  activator_id: authenticationContext.user.data?.id,
                  question_id: currentQuestion?.id,
                },
                media,
                sourceId: selectedSource,
                qtyReceived: receivedCounter,
                qtySent: sentCounter,
                text,
              },
            });
          } else {
            addAnswerText({
              variables: {
                answer: {
                  activator_id: authenticationContext.user.data?.id,
                  question_id: currentQuestion?.id,
                },
                sourceId: selectedSource,
                qtyReceived: receivedCounter,
                qtySent: sentCounter,
                text,
              },
            });
          }
          setAnswered();
          handlePageChange();
          cleanStates();
        } catch (e) {
          setErrorMessages((prevState) => [...prevState, 'Ocorreu um erro ao enviar a resposta']);
        }
      }
    }
  };

  return (
    <Content>
      {errorMessages.map((errorMessage) => (
        <ErrorMessage key={errorMessage}>{errorMessage}</ErrorMessage>
      ))}

      {currentQuestion && currentQuestion.text && (
        <>
          <Text>{currentQuestion.text}</Text>

          <ButtonContainer>
            <SelectButton
              className={receivedAnyContent === 'TRUE' ? 'selected' : ''}
              onClick={() => setReceivedAnyContent('TRUE')}
            >
              Sim
            </SelectButton>
            <SelectButton
              className={receivedAnyContent === 'FALSE' ? 'selected' : ''}
              onClick={() => setReceivedAnyContent('FALSE')}
            >
              Não
            </SelectButton>
          </ButtonContainer>
        </>
      )}
      <div
        style={{
          display:
            receivedAnyContent === 'TRUE' || (currentQuestion && !currentQuestion.text) ? 'block' : 'none',
        }}
      >
        <Text>Qual tipo de conteúdo você vai enviar?</Text>
        <ButtonContainer>
          <SelectButton
            className={contentType === 'IMAGE' ? 'selected' : ''}
            onClick={() => handleContentTypeChange('IMAGE')}
          >
            Imagem
          </SelectButton>
          <SelectButton
            className={contentType === 'GIF' ? 'selected' : ''}
            onClick={() => handleContentTypeChange('GIF')}
          >
            GIF
          </SelectButton>
          <SelectButton
            className={contentType === 'VIDEO' ? 'selected' : ''}
            onClick={() => handleContentTypeChange('VIDEO')}
          >
            Vídeo
          </SelectButton>
          <SelectButton
            className={contentType === 'AUDIO' ? 'selected' : ''}
            onClick={() => handleContentTypeChange('AUDIO')}
          >
            Áudio
          </SelectButton>
          <SelectButton
            className={contentType === 'TEXT' ? 'selected' : ''}
            onClick={() => handleContentTypeChange('TEXT')}
          >
            Texto
          </SelectButton>
        </ButtonContainer>

        {['IMAGE', 'GIF', 'VIDEO', 'AUDIO'].includes(contentType) && (
          <InputLabel>
            Mídia
            <InputFileLabel>
              <Input type="file" onChange={handleFileChange} />

              {!media && 'Clique aqui para adicionar um arquivo'}

              {errorMessageMedia && <ErrorMessage>{errorMessageMedia}</ErrorMessage>}

              {media && imageTypes.includes(media.type) && (
                <img className="media" src={URL.createObjectURL(media)} alt="Logo" />
              )}

              {media && videoTypes.includes(media.type) && (
                <video className="media" controls>
                  <source src={URL.createObjectURL(media)} type={media.type} />
                </video>
              )}

              {media && audioTypes.includes(media.type) && (
                <audio controls>
                  <source src={URL.createObjectURL(media)} type={media.type} />
                </audio>
              )}

              {media && (
                <button type="button" onClick={removeMedia}>
                  Remover
                </button>
              )}
            </InputFileLabel>
          </InputLabel>
        )}
        {['TEXT'].includes(contentType) && (
          <InputLabel>
            Texto
            <TextArea rows={7} value={text} onChange={(event) => setText(event.currentTarget.value)} />
          </InputLabel>
        )}
        <div style={{ display: contentType !== '' ? 'block' : 'none' }}>
          <InputLabel>
            De qual grupo você recebeu este conteúdo?
            <select value={selectedSource} onChange={(e) => setSelectedSource(e.currentTarget.value)}>
              <option key="default" value="">
                Selecione uma opção
              </option>
              {sources &&
                sources.map((source) => (
                  <option key={params.sequence + source.name} value={source.id}>
                    {source.name}
                  </option>
                ))}
            </select>
          </InputLabel>

          <CounterLabel>
            Quantas vezes você recebeu este conteúdo?
            <CounterContainer>
              <CounterButton
                onClick={() => setReceivedCounter((prevState) => (prevState > 1 ? prevState - 1 : 1))}
              >
                -
              </CounterButton>
              <Input
                type="text"
                min="1"
                max="999"
                step="1"
                value={receivedCounter}
                onChange={(e) =>
                  setReceivedCounter(Number(e.currentTarget.value.replace(/[^0-9]/g, '') || 1))
                }
              />
              <CounterButton onClick={() => setReceivedCounter((prevState) => prevState + 1)}>
                +
              </CounterButton>
            </CounterContainer>
          </CounterLabel>
          <CounterLabel>
            Quantas vezes você encaminhou este conteúdo?
            <CounterContainer>
              <CounterButton
                onClick={() => setSentCounter((prevState) => (prevState > 1 ? prevState - 1 : 0))}
              >
                -
              </CounterButton>
              <Input
                type="text"
                min="0"
                max="999"
                step="1"
                value={sentCounter}
                onChange={(e) => setSentCounter(Number(e.currentTarget.value.replace(/[^0-9]/g, '') || 0))}
              />
              <CounterButton onClick={() => setSentCounter((prevState) => prevState + 1)}>+</CounterButton>
            </CounterContainer>
          </CounterLabel>
          <InputLabel>
            <div className="checkbox">
              <div>
                <Input
                  type="checkbox"
                  className="form-check-input"
                  checked={defaultCheck}
                  onChange={() => setDefaultCheck((prevState) => !prevState)}
                />
              </div>
              <div>
                Asseguro que o conteúdo não traz informações ou imagens pessoais minhas ou de pessoas próximas
                a mim
              </div>
            </div>
          </InputLabel>
          <InputLabel style={{ display: currentQuestion && currentQuestion.theme ? 'block' : 'none' }}>
            <div className="checkbox">
              <div>
                <Input
                  type="checkbox"
                  className="form-check-input"
                  checked={especificCheck}
                  onChange={() => setEspecificCheck((prevState) => !prevState)}
                />
              </div>
              <div>
                Asseguro que o conteúdo inserido é relacionado à{' '}
                {currentQuestion ? currentQuestion.theme : ''}
              </div>
            </div>
          </InputLabel>
        </div>
      </div>

      <SendButton onClick={() => handleSubmit()}>
        {currentPage === questionsContext.questions?.length ? 'Finalizar' : 'Próxima'}
      </SendButton>
    </Content>
  );
}

export default SendMediaSequence;
