export const STALLED_CAPTURE_MODULE =
  'No momento essa pesquisa está paralisada. Entraremos em contato quando ela for retomada!';

export const ACKNOWLEDGMENT =
  'Agradecemos sua contribuição! Contamos com a sua participação novamente amanhã!';

export const ALREADY_SENT =
  'Você já participou da pesquisa hoje. Contamos com a sua participação novamente amanhã!';

export const ERROR = 'Ocorreu um erro. Tente novamente mais tarde.';
