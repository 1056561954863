import { createContext, useMemo, useState } from 'react';
import User from '../@types/user';

type context = {
  user: {
    data: User | undefined;
    setData: ((user: User) => void) | undefined;
  };
  module: {
    data: string | undefined;
    setData: ((module: string | undefined) => void) | undefined;
  };
};

export const defaultValue: context = {
  user: {
    data: undefined,
    setData: undefined,
  },
  module: {
    data: undefined,
    setData: undefined,
  },
};

export const AuthenticationContext = createContext<context>(defaultValue);

export function AuthenticationContextProvider({ children }: { children: JSX.Element }): JSX.Element {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [module, setModule] = useState<string | undefined>(undefined);

  const userMemo = useMemo(() => {
    if (!user) {
      const u = localStorage.getItem('@inside/user');
      if (u) {
        setUser(JSON.parse(u));
      }
    }
    return user;
  }, [user]);

  const setUserMemo = (param: User): void => {
    setUser(param);
    localStorage.setItem('@inside/user', JSON.stringify(param));
  };

  const moduleMemo = useMemo(() => {
    if (!module) {
      const m = localStorage.getItem('@inside/module');
      if (m) {
        setModule(JSON.parse(m));
      }
    }
    return module;
  }, [module]);

  const setModuleMemo = (param: string | undefined): void => {
    setModule(param);
    localStorage.setItem('@inside/module', JSON.stringify(param));
  };

  const value: context = useMemo(() => {
    return {
      user: {
        data: userMemo,
        setData: setUserMemo,
      },
      module: {
        data: moduleMemo,
        setData: setModuleMemo,
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, module]);

  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>;
}
