import { ApolloError, gql, QueryLazyOptions, useLazyQuery } from '@apollo/client';
import User from '../../../@types/user';

type returnType = {
  login: (options?: QueryLazyOptions<{ email: string; password: string }> | undefined) => void;
  data: User;
  loading: boolean;
  error: ApolloError | undefined;
};

const LOGIN = gql`
  query LoginActivator($email: String, $password: String) {
    loginActivator(email: $email, password: $password) {
      id
      name
      code
      active
    }
  }
`;

export function useLogin(): returnType {
  const [login, { data: result, loading, error }] = useLazyQuery(LOGIN);

  return { login, data: result?.loginActivator, loading, error };
}
