import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from '../../assets/styles/global';
import defaultTheme from '../../assets/styles/themes/default';
import { AuthenticationContextProvider } from '../../contexts/Authentication';
import { QuestionsContextProvider } from '../../contexts/Questions';
import Router from '../../Routes';
import client from '../../services/ApolloClient';

function App(): JSX.Element {
  return (
    <BrowserRouter>
      <ThemeProvider theme={defaultTheme}>
        <ApolloProvider client={client}>
          <AuthenticationContextProvider>
            <QuestionsContextProvider>
              <>
                <GlobalStyles />
                <Router />
              </>
            </QuestionsContextProvider>
          </AuthenticationContextProvider>
        </ApolloProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
