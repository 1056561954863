import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 90vh;
  color: #fff;
  margin-top: 16px;

  .media {
    height: 100px;
  }
`;

export const SelectButton = styled.button`
  border: 2px solid ${({ theme }) => theme.colors.primary.main};
  border-radius: 4px;
  padding: 8px 16px;
  color: #fff;
  background-color: transparent;
  display: inline-block;
  margin-right: 8px;
  margin-top: 8px;

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.primary.dark};
  }

  &.selected {
    background-color: ${({ theme }) => theme.colors.primary.main};
    font-weight: 700;

    &:hover {
      border: 2px solid ${({ theme }) => theme.colors.primary.dark};
      background-color: ${({ theme }) => theme.colors.primary.dark};
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
`;

export const Text = styled.p`
  font-size: 16px;
  margin-top: 16px;
`;

export const InputLabel = styled.label`
  font-size: 14px;
  margin-top: 24px;
  width: 100%;

  select {
    width: 100%;
    margin-top: 8px;
    height: 32px;
  }

  .checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const InputFileLabel = styled.label`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 168px;
  padding: 16px;
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.primary.main};
  border-radius: 4px;
  font-size: 16px;

  input {
    display: none;
  }

  button {
    background-color: transparent;
    border: 2px solid ${({ theme }) => theme.colors.primary.main};
    border-radius: 4px;
    color: #fff;
    margin-top: 8px;
  }
`;

export const CounterLabel = styled.div`
  font-size: 14px;
  margin-top: 24px;
  width: 100%;

  select {
    width: 100%;
    margin-top: 8px;
    height: 32px;
  }
`;

export const CounterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 8px;

  input {
    margin-top: 0;
  }
`;

export const CounterButton = styled.button`
  border: 2px solid ${({ theme }) => theme.colors.primary.main};
  border-radius: 4px;
  height: 32px;
  width: 40px;
  color: #fff;
  background-color: transparent;
  margin-right: 8px;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  transition: background-color 0.2s ease-in-out;

  input + & {
    margin-left: 8px;
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const ErrorMessage = styled.p`
  color: #fd4141;
`;

export const Input = styled.input`
  &[type='text'] {
    height: 32px;
    text-align: center;
  }

  &.form-check-input {
    display: block;
    margin-top: 0;
    height: 16px;
    width: 16px;
    margin-right: 16px;
    &:checked {
      background-color: ${({ theme }) => theme.colors.primary.main};
      border-color: ${({ theme }) => theme.colors.primary.main};
    }
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.colors.primary.main};
`;

export const SendButton = styled.button`
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  color: #fff;
  background-color: ${({ theme }) => theme.colors.primary.main};
  display: inline-block;
  margin-right: 8px;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
  min-width: 100px;
`;
